<template>
  <router-link
    v-if="srcValue && currentAccountIsSubscribed"
    class="cursor-pointer"
    :to="{ path: '/' }"
  >
    <img
      width="100px"
      height="100%"
      loading="lazy"
      :src="srcValue"
      alt="SuperScoute"
      @error="onError"
    />
  </router-link>
  <router-link v-else-if="$isSubscribed" class="cursor-pointer" :to="{ path: '/' }">
    <img width="100%" height="100%" loading="lazy" :src="logoURL" alt="SuperScoute" />
  </router-link>
  <img
    v-else
    width="100%"
    height="100%"
    loading="lazy"
    class="mt-4"
    :src="logoURL"
    alt="SuperScoute"
  />
</template>

<script>
// @group SVG
export default {
  name: 'Logo',

  props: {
    color: { type: String, default: '' },
    width: { type: String },
    src: { type: String, default: '' }
  },

  data() {
    return {
      colorInput: this.color,
      srcValue: null,
      logoURL: `${this.assetURL}/logo.png`,
      currentAccountIsSubscribed: false
    }
  },

  mounted() {
    this.currentAccountIsSubscribed = this.$isSubscribed
    this.srcValue = this.src
  },

  watch: {
    color(value) {
      this.colorInput = value
    }
  },

  methods: {
    onError() {
      const match = this.srcValue.match('/400/|/800/|/1600/')
      const matchOriginal = this.srcValue.match('/original/')
      if (matchOriginal && matchOriginal[0]) {
        this.srcValue = this.logoURL
      } else if (match && match[0]) {
        this.srcValue = this.srcValue.replace(match[0], '/original/')
      }
    }
  }
}
</script>
