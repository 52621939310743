import routeHelper from './helper'

export default [
  {
    path: '/search',
    name: 'search.view',
    component: () => import('@views/SearchView.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      hideFooter: true,
      breadcrumb: (name) => name || 'Library',
      event: () => 'Search',
      link: (route) => `/`
    },
    beforeEnter: routeHelper.beforeEnter
  },

  {
    path: '/location/contact',
    name: 'location.contact.view',
    component: () => import('@views/location/create/LocationContact.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/:accountId/c/:collectionId/:locationId',
    name: 'collection.location.view',
    component: () =>
      import('@views/collections/list/location/index.vue').then((c) => c.default || c),
    meta: {
      breadcrumb: (name) => name || 'Collection location view',
      event: () => 'Collections location view',
      link: (route) => `/c/${route.params.collectionId}/${route.params.locationId}`
    },
    beforeEnter: routeHelper.beforeEnter,
    children: [
      {
        path: 'photos/:rank',
        name: 'collection.location.photos.view',
        component: () => import('@views/photos/view/index.vue'),
        meta: {
          requiresAuth: true,
          eventCategory: 'Collections Location Photo View',
          breadcrumb: (name) => name || 'Media Gallery',
          hideFooter: true
        }
      }
    ]
  },
  {
    path: '/:accountId/:locationId/share/preview/:shareId',
    name: 'location.share.preview',
    component: () => import('@views/location/share/preview/index.vue'),
    meta: {
      title: 'Location Share',
      requiresAuth: true,
      hideHeader: true,
      hideFooter: true,
      redirect: 'location.list',
      event: () => 'Location Share Preview',
      breadcrumb: (name) => name || 'Shared location',
      link: (route) =>
        `/${route.params.accountId}/${route.params.locationId}/share/preview/${route.params.shareId}`,
      hideHomeBreadcrumb: true
    },
    children: [
      {
        path: 'photos/:rank',
        name: 'location.share.photos.preview',
        component: () => import('@views/location/share/view/focusView.vue'),
        meta: {
          breadcrumb: (name) => name || 'Focus view',
          event: (route) => `${route.params.shareId}`,
          link: (route) => `/l/${route.params.shareId}/photos/${route.params.rank}`
        }
      }
    ]
  },
  {
    path: '/:accountId/p/:propertyId',
    name: 'property.view',
    component: () => import('@views/property/view/index.vue'),
    meta: {
      requiresAuth: true,
      eventCategory: 'Property View',
      event: (route) => `${route.params.propertyId}`,
      link: (route) => `/${route.params.accountId}/p/${route.params.propertyId}`,
      breadcrumb: (name) => name || 'Property View',
      hideFooter: true
    },
    beforeEnter: routeHelper.beforeEnter,
    children: [
      {
        path: '/:accountId/p/:propertyId/:locationId',
        name: 'location.view',
        component: () => import('@views/location/view/index.vue'),
        meta: {
          requiresAuth: true,
          eventCategory: 'Location View',
          event: (route) => `${route.params.locationId}`,
          link: (route) =>
            `/${route.params.accountId}/p/${route.params.propertyId}/${route.params.locationId}`,
          breadcrumb: (name) => name || 'Location View',
          hideFooter: true
        },
        children: [
          {
            path: 'photos/:rank',
            name: 'photos.view',
            component: () => import('@views/photos/view/index.vue'),
            meta: {
              requiresAuth: true,
              eventCategory: 'Location Photo View',
              breadcrumb: (name) => name || 'Media Gallery',
              event: (route) => `${route.params.locationId}`,
              link: (route) =>
                `/${route.params.accountId}/p/${route.params.propertyId}/${route.params.locationId}/photos/${route.params.rank}`,
              hideFooter: true
            }
          },
          {
            path: 'export',
            name: 'location.export',
            component: () => import('@components/location/view/VenuePhotosForExport.vue'),
            meta: {
              requiresAuth: true,
              eventCategory: 'Location Photo Export',
              breadcrumb: (name) => name || 'Export photos selection',
              event: (route) => `${route.params.locationId}`,
              link: (route) => `/${route.params.accountId}/${route.params.locationId}/export`,
              hideActionBar: true,
              hideFooter: true
            }
          }
        ]
      }
    ]
  }

  // {
  //   path: '/terms-policies',
  //   name: 'page.terms',
  //   component: () => import('@views/Terms.vue').then((c) => c.default || c),
  //   meta: {
  //     title: 'Terms & Conditions',
  //     event: () => 'Terms & Conditions'
  //   }
  // }
]
